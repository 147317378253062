.table-design {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  .td-design, .th-design {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  .tr-design:nth-child(even) {
    background-color: #E8F9FF;
  }
  
  .table-container {
    display: flex;
    flex-wrap: wrap; /* Allows wrapping to the next line */
    gap: 16px; /* Adds space between tables */
  }
  
  .table-wrapper {
    flex: 1 1 300px; /* Flex-grow, flex-shrink, and base width */
    min-width: 300px; /* Minimum width to prevent squishing */
    max-width: 100%; /* Ensures tables don't exceed container width */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
    overflow-x: auto; /* Enables horizontal scrolling if table is too wide */
  }
  
  @media (max-width: 768px) {
    .table-wrapper {
      flex: 1 1 100%; /* Tables stack in a single column on smaller screens */
    }
  }


  .timeline {
    display: flex;
    align-items: center;
    overflow-x: auto;
    padding: 20px;
    white-space: nowrap;
  }

  .timeline-item {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    position: relative;
  }

  .timeline-item::before {
    content: '';
    position: absolute;
    top: 25%;
    left: -50px;
    width: 80px;
    height: 3px;
    background-color: #ECE852;
    z-index: 1;
  }

  .timeline-item:first-child::before {
    content: none;
  }

  .timeline-dot {
    width: 35px;
    height: 35px;
    background-color: #16C47F;
    border-radius: 50%;
    z-index: 2;
    border: 4px solid #fff;
    color:white;
    padding-left:9px;
    padding-top:2px;
  }

  .timeline-content {
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    color: #333;
  }