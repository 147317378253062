.login-form {
    max-width: 400px;
  }
.login-form-forgot {
    float: right;
    color:#faa61a;
  }
.resend-code {
    float: center;
    color:#faa61a;
    border:none;
    background-color: white;
  }
.login-form-button {
    width: 40%;
    height: 40px;
    background-color: #1A3337;
    border-color: #1A3337;
    color: white;
    margin:0 auto;
    display: block;
    font-size: large;
    border-radius: 25px;
  }
.login-signup-toggle-button {
  width:100%;
  height:40px;
  margin-bottom:15px;
  background-color:#F4F6FF;
  border:none;
  color:#686D76;
  border-radius:25px;
  font-size: 18px;
  }

.card {
    margin-left:auto;
    margin-right:50px;
    min-width: 450px;
    height: 465px;
    opacity:0.9;
    box-shadow: 18px 20px 24px -4px rgba(0,0,0,0.72);
}

.container {
    padding-top: 10px;
    height: 100vh;
    background-position:center;
    background-size: cover;
    background-repeat: no-repeat;
}

html,body{
    background-color:white;
}


.ant-tabs-tab {
  width:150px
}

.borderless-button{
  border: none;
  background: transparent;
}

.card-grid{
  width: 100%;
  text-align: center;
  font-weight: 700;
}

.cardscontainer {
  display: flex;
  flex-wrap: wrap;
 
}

@media screen and (max-width: 1300px) {
  

  .cardsingle {
      flex: 0 1 calc(50% - 20px);
      margin:10px;
      justify-content: center; 
  }
}

@media screen and (min-width: 1301px) {

  .cardsingle {
      flex: 0 1 calc(33.333% - 20px);
      margin:10px;
  }
}

.view{
  margin:10px;
  width:100%;
  font-size: 17px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.MuiPickersBasePicker-container .MuiPickersToolbar-toolbar {
  background-color: #1A3337;
}

.MuiPaper-root .MuiTabs-root  {
  background-color: #1A3337;
}

 .MuiPickersCalendar-week .MuiPickersDay-daySelected {
  background-color: #1A3337;
}


.MuiPickersClockNumber-clockNumberSelected {
  background-color: #1A3337;
}

.MuiPickersClockPointer-pointer .MuiPickersClockPointer-thumb {
  background-color: #1A3337;
  border: 14px solid #1A3337
}

.MuiPickersClock-clock .MuiPickersClockPointer-pointer{
  background-color: #1A3337;
}

.MuiPickersClock-clock .MuiPickersClock-pin{
  background-color: #1A3337;
}

.MuiDialogActions-root .MuiButton-textPrimary{
  color: #1A3337;
}

 .MuiTabs-scroller .MuiTabs-indicator {
  background-color: #faa61a;
}

.login-signup-logo{
  position:absolute;
  top:30px;
  left:30px;
}

@font-face {
  font-family: "Light";   /*Can be any text*/
  src: local("InterVariable"),
    url("../fonts/InterVariable.ttf") format("truetype");
}

.ant-typography{
  font-family: "Light";
}

h1,h2,h3,h4,h5,h6,p,span,text{
  font-family: "Light";
}




.tooltip-button {
  border: 5px solid #0A97B0;
  position: fixed;
  z-index: 100;
  bottom: 30px;
  right: 20px;
  background-color: #1A3337;
  height: 60px;
  width: 60px;
  font-size: 30px;
  padding-bottom: 7px;
  padding-right: 2px;
  color: white;
}

