.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.header {
  background-color: #1A3337;
  color: white;
  position: fixed; 
  z-index:  1; 
  display: flex;
  flex-direction: row;
  width: 100%;
}

.title{
  padding-left: 30px;
  
}

.text{
  color: white;
}

.icon{
  font-size: 21px;
  font-weight: bold;
  margin-top:8px;
  margin-left:10px;
  color: #1A3337;
}

.ant-menu-item-selected a {
  color: #1A3337;
}

.ant-menu-item-selected a:hover {
  color: #1A3337;
}

.ant-menu-inline .ant-menu-item::after {
  border-right:3px solid #1A3337;
}

.ant-menu-item .ant-tooltip-ope .ant-menu-item-active {
  color: #1A3337;
}

.ant-layout-sider-trigger{
  background-color: white;
}

.ant-layout-header{
  padding-left: 20px;
  padding-right: 20px;
}

.ant-layout-content{
  background-color: white;
}

.leaflet-container {
  height: 400px;
  width: 100%;
}

.yellow{
  color: #faa61a;
}

.secondary{
  color: #1A3337;
}

.primary{
  color: #1A3337;
}

.ant-btn .ant-input-search-button .ant-btn-primary{
  background-color:#1A3337 ;
}

.ant-btn-primary{
  background-color: #1A3337;
  border-color: #1A3337;
}

.side-bar{
  overflow: scroll;
}

.side-bar::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}



.task-view-text{
  /* font-family: AirbnbCerealApp-Book; */
  letter-spacing: 1;
  font-size: medium;
  padding: 10px;
}

.MuiPickersBasePicker-container .MuiPickersToolbar-toolbar {
  background-color: #1A3337;
}

.MuiPaper-root .MuiTabs-root  {
  background-color: #1A3337;
}

 .MuiPickersCalendar-week .MuiPickersDay-daySelected {
  background-color: #1A3337;
}


.MuiPickersClockNumber-clockNumberSelected {
  background-color: #1A3337;
}

.MuiPickersClockPointer-pointer .MuiPickersClockPointer-thumb {
  background-color: #1A3337;
  border: 14px solid #1A3337
}

.MuiPickersClock-clock .MuiPickersClockPointer-pointer{
  background-color: #1A3337;
}

.MuiPickersClock-clock .MuiPickersClock-pin{
  background-color: #1A3337;
}

.MuiDialogActions-root .MuiButton-textPrimary{
  color: #1A3337;
}

.MuiTabs-scroller .MuiTabs-indicator{
  background-color: #faa61a;
}

@font-face {
  font-family: "Light";   /*Can be any text*/
  src: local("InterVariable"),
    url("../fonts/InterVariable.ttf") format("truetype");
}

.ant-typography{
  font-family: "Light";
}
h1,h2,h3,h4,h5,h6,p,span,text{
  font-family: "Light";
}

/* .ant-menu-root.ant-menu-vertical, .ant-menu-root.ant-menu-vertical-left, .ant-menu-root.ant-menu-vertical-right, .ant-menu-root.ant-menu-inline{
  background-color:#151135 ;
} */

.polyline-container {
  position: absolute;
  pointer-events: none;
}

.polyline {
  height: 3px;
  background-color: purple;
  position: absolute;
  transition: width 0.5s linear;
}

.arrow {
  width: 10px;
  height: 10px;
  background-color: red;
  position: absolute;
  clip-path: polygon(0 0, 100% 50%, 0 100%);
  transition: transform 0.3s ease-in-out;
}

.sidebar {
  position: absolute;
  right: 10px; /* Move sidebar to the right */
  top: 10px;
  width: 350px; /* Increase width for better readability */
  max-height: 90vh;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

h3 {
  text-align: center;
  margin-bottom: 10px;
  font-weight: 600;
  color: #3674B5;
}

.timeline {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-end; /* Right-align the cards */
}

.timeline-card {
  background: #f8f9fa;
  padding: 12px;
 width:100%; /* Increase card width */
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  border-left: 4px solid #FF6868; /* Add left border for a timeline effect */

}

.timeline-card:hover {
  background: #e9ecef;
}

.timeline-card h4 {
  margin: 0;
  color: #3674B5;
  font-size: 16px;
}

.timeline-card p {
  margin: 5px 0;
  font-size: 14px;
  color: #3674B5;
}

/* Start Animation Button Inside Card */
.start-btn {
  margin-top: 10px;
  width: 100%;
  background: #3674B5; /* Matching color */
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.start-btn:hover {
  background: #3674B5;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.custom-arrow-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-container {
  animation: flash 0.8s infinite alternate;
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}